import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';

import './style.scss';


const ContainerBody = observer(() => {

    return (
        <div className='container-with-sidebar d-flex min-height-820 w-100'>
            <SideBar />
            <div className='container-with-sidebar-content' >
                <div className=''>
                    {
                        <Outlet />
                    }
                </div>
            </div>
        </div>
    )
})

export default ContainerBody;