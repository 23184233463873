/* eslint-disable indent */
/* eslint-disable max-len */
import moment from 'moment';
import { HOLDER_TYPE, SCREEN_MODE, SYSTEM_PATH, TRANSACTION_STATUS } from '../../core/configs/constants';
import { FormatNumber } from '../../components';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import './style.scss';


const Card = (props) => {

    // other
    const navigate = useNavigate();

    const { 
        holderId, 
        mode = SCREEN_MODE.HOLDER_SCREEN , 
        cardInfo: { 
            id, image, coin = 'SML', publicAt, exchange = 'MEXC', cardPrice = 0,
            variationRate = 0, baseRate = 0, currentRate = 0, airdrop = 0, currentPrice = 0, code = '', notifyValue, notifyFlag, transaction, account,
            isLock = false 
        }, 
        expiryStartDate,
        holderType,
        accountId,
        isPayMonthly,
        checkCanDoPurchase,
        holderTransaction,
        openConfirmRemoveCard, 
        openSetNotification, 
        openPurchaseCard,
        onConfirmChangeStatusLockCard,
        countCardLockStatus,
        sortCardStatus,
        setSortCardStatus,
        onExitSortCard
    } = props;

    const onClickExtendHolder = () => {
        navigate(SYSTEM_PATH.EXTEND_HOLDER, {state: { accountId, holderId } })
    }

    return <div className='holder-card'>
        <div className='holder-card-group-button'>
            {
                sortCardStatus?.cardId === undefined && (
                    <>
                        {
                            mode == SCREEN_MODE.HOLDER_SCREEN ? 
                            <div className='holder-card-btn'
                                role='button' onClick={() => openSetNotification({ id, notifyFlag, notifyValue })}>
                                <img src='/images/notification.svg'/>
                            </div> : <></>
                        }
                        {
                            holderType === HOLDER_TYPE.FEE && isPayMonthly && expiryStartDate && checkCanDoPurchase && checkCanDoPurchase.canPurchase && (
                                <div className='holder-card-btn holder-card-extend-btn' onClick={onClickExtendHolder} title='Holderのサブスクを延長'> 
                                    <img src='/images/extend_holder.svg' />
                                </div>
                            )
                        }
                        {
                            mode == SCREEN_MODE.HOLDER_SCREEN &&
                            <>
                                <div className='holder-card-btn lock-card-bg' onClick={() => onConfirmChangeStatusLockCard(id, !isLock)}>
                                    {
                                        isLock ? 
                                        <img src='/images/lock_card.svg' /> :
                                        <img src='/images/unlock_card.svg' />
                                    }
                                </div>
                                {
                                    ((isLock && countCardLockStatus?.lock > 1) || (!isLock && countCardLockStatus?.unlock > 1)) && (
                                        <div className='holder-card-btn sort-card-bg' onClick={() => setSortCardStatus && setSortCardStatus({
                                            cardId: id,
                                            isLock
                                        })}>
                                            {
                                                sortCardStatus?.cardId === id ? 
                                                    <img src='/images/sort_card_active.svg'/> :
                                                    <img src='/images/sort_card.svg' />
                                            }
                                        </div>
                                    )
                                }
                            </>
                        }
                    </>
                )
            }
        </div>
        <div className="holder-card-item row pd-lr-20 pd-t-10 pd-b-5">
            <div className="holder-card-img d-flex justify-content-center
             align-items-center col-12 col-xxl-4 col-lg-12 col-md-4">
                <img src={image || '/images/gold_card.png'} />
            </div>
            <div className="holder-card-content col-12 col-xxl-8 col-md-8 col-lg-12 d-flex flex-column ">
                <div className='card-balance-info mt-xl-2 mt-sm-2 row'>
                    <div className='col-md-10 justify-content-center d-flex'>
                        {currentRate > baseRate && <>
                            <div className='balance-icon'>
                                <img className='text-color-cs-14' src='/images/icon_rate_up.svg' />
                            </div>
                            <div className='balance-item d-flex justify-content-center flex-column mg-l-5'>
                                <p className='balance-title text-color-cs-14 fs-label'>騰落率</p>
                                <p className='balance-content '><FormatNumber className='text-color-cs-14 fs-label font-semi-bold' statusDisplay='text' prefix='+' value={variationRate} decimalScale={2} suffix='%' /></p>
                            </div>
                        </>}
                        {currentRate < baseRate && <>
                            <div className='balance-icon'>
                                <img src='/images/icon_down_rate.svg' />
                            </div>
                            <div className='balance-item d-flex justify-content-center flex-column mg-l-5'>
                                <p className='balance-title text-color-cs-red fs-label'>騰落率</p>
                                <p className='balance-content '><FormatNumber className='text-color-cs-red font-semi-bold' statusDisplay='text' allowNegative value={variationRate} decimalScale={2} suffix='%' /></p>
                            </div>
                        </>}
                        {currentRate === baseRate && <>
                            <div className='balance-icon'>
                                <img src='/images/icon_equal_rate.svg' />
                            </div>
                            <div className='balance-item d-flex justify-content-center flex-column mg-l-5'>
                                <p className='balance-title fs-label'>騰落率</p>
                                <p className='balance-content'><FormatNumber className='font-semi-bold' statusDisplay='text' value={variationRate} decimalScale={2} suffix='%' /></p>
                            </div>
                        </>}
                    </div>
                    <div className='col-md-2'></div>
                </div>
                <div className='card-item mg-t-5 row'>
                    <div className='col-6 card-item-detail d-flex '>
                        <div className='card-item-icon d-flex justify-content-center align-items-center'>
                            <img src='/images/icon_coin.svg' />
                        </div>
                        <div className='card-item-info mg-l-10'>
                            <p className='info-title text-color-cs-13'>コイン</p>
                            <p className='info-content font-semi-bold'>{coin}</p>
                        </div>
                    </div>
                    <div className='col-6 card-item-detail d-flex'>
                        <div className='card-item-icon d-flex justify-content-center align-items-center'>
                            <img src='/images/icon_home.svg' />
                        </div>
                        <div className='card-item-info mg-l-10'>
                            <p className='info-title text-color-cs-13'>取引所</p>
                            <p className='info-content font-semi-bold'>{exchange}</p>
                        </div>
                    </div>
                    <div className='col-6 card-item-detail d-flex mg-t-10'>
                        <div className='card-item-icon d-flex justify-content-center align-items-center'>
                            <img src='/images/icon_calendar.svg' />
                        </div>
                        <div className='card-item-info mg-l-10'>
                            <p className='info-title text-color-cs-13'>発行日時</p>
                            <p className='info-content font-semi-bold'>{publicAt ? moment(new Date(publicAt)).format('YYYY/MM/DD HH:mm') : ''}</p>
                        </div>
                    </div>
                    <div className='col-6 card-item-detail d-flex mg-t-10'>
                        <div className='card-item-icon d-flex justify-content-center align-items-center'>
                            <img src='/images/icon_currency.svg' />
                        </div>
                        <div className='card-item-info mg-l-10'>
                            <p className='info-title text-color-cs-13'>Airdrop</p>
                            <p className='info-content font-semi-bold'><FormatNumber statusDisplay='text' value={airdrop} suffix=' SML' /></p>
                        </div>
                    </div>
                    <div className='col-6 card-item-detail d-flex mg-t-10'>
                        <div className='card-item-icon d-flex justify-content-center align-items-center'>
                            <img src='/images/icon_rate_buy.svg' />
                        </div>
                        <div className='card-item-info mg-l-10'>
                            <p className='info-title text-color-cs-13'>基準レート</p>
                            <p className='info-content font-semi-bold'><FormatNumber statusDisplay='text' allowNegative value={baseRate} decimalScale={6} suffix=' USDT'/></p>
                        </div>
                    </div>
                    <div className='col-6 card-item-detail d-flex mg-t-10'>
                        <div className='card-item-icon d-flex justify-content-center align-items-center'>
                            <img src='/images/icon_purchase_price.svg' />
                        </div>
                        <div className='card-item-info mg-l-10'>
                            <p className='info-title text-color-cs-13'>カード価格</p>
                            <p className='info-content font-semi-bold'><FormatNumber statusDisplay='text' value={cardPrice} decimalScale={0} suffix=' 円' /></p>
                        </div>
                    </div>
                    <div className='col-6 card-item-detail d-flex mg-t-10'>
                        <div className={classNames('card-item-icon d-flex justify-content-center align-items-center' ,variationRate > 0 ? 'up-rate' : variationRate < 0 ? 'down-rate' : 'balance')}>
                            <img className={classNames(variationRate > 0 ? 'up-rate' : variationRate < 0 ? 'down-rate' : '')} src='/images/icon_curent_rate.svg' />
                        </div>
                        <div className='card-item-info mg-l-10'>
                            <p className='info-title text-color-cs-13'>現在レート</p>
                            <p className='info-content font-semi-bold'><FormatNumber statusDisplay='text' allowNegative value={currentRate} decimalScale={6} suffix=' USDT' /></p>
                        </div>
                    </div>
                    <div className='col-6 card-item-detail d-flex mg-t-10'>
                        <div className={classNames('card-item-icon d-flex justify-content-center align-items-center' ,variationRate > 0 ? 'up-rate' : variationRate < 0 ? 'down-rate' : 'balance')}>
                            <img src='/images/icon_purchase_price.svg' />
                        </div>
                        <div className='card-item-info mg-l-10'>
                            <p className='info-title text-color-cs-13'>現在価値</p>
                            <p className='info-content font-semi-bold'><FormatNumber statusDisplay='text' value={currentPrice} decimalScale={0} suffix=' 円' /></p>
                        </div>
                    </div>
                    <div className={classNames('card-item-detail d-flex mg-t-10', mode == SCREEN_MODE.HOLDER_SCREEN ? 'col-12' : 'col-6')}>
                        <div className='card-item-icon d-flex justify-content-center align-items-center'>
                            <img src='/images/icon_card-code.svg' />
                        </div>
                        <div className='card-item-info mg-l-10'>
                            <p className='info-title text-color-cs-13'>管理番号</p>
                            <p className='info-content font-semi-bold'>{code}</p>
                        </div>
                    </div>
                    {
                        mode == SCREEN_MODE.HOLDER_SCREEN && (
                            <div className={classNames('card-item-detail mg-t-10 col-11', expiryStartDate ? 'visible' : 'invisible')}>
                                <div className='bg-cs-lighter-gray pd-lr-14 pd-tb-7 border-radius-8 text-center'>
                                    <span className='text-color-cs-12'>Holderの有効期限 : </span>
                                    <span>{
                                        moment(expiryStartDate).startOf('minute').format('YYYY/MM/DD HH:mm') 
                                    }</span>
                                </div>
                            </div>
                        )
                    }
                    {
                        mode == SCREEN_MODE.PURCHASE_CARD_SCREEN && 
                        <div className='col-6 card-item-detail d-flex mg-t-10'>
                            <div className='card-item-icon d-flex justify-content-center align-items-center'>
                                <i className='fa-solid fa-user text-color-cs-1' />
                            </div>
                            <div className='card-item-info mg-l-10'>
                                <p className='info-title text-color-cs-13'>所有中アカウント</p>
                                <p className='info-content font-semi-bold'>{account?.userName ?? ''}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        {
            mode == SCREEN_MODE.HOLDER_SCREEN ? 
            (
                sortCardStatus?.cardId === undefined ? 
                (
                    (transaction == null || (transaction && (transaction.status === TRANSACTION_STATUS.FAILED || transaction.status === TRANSACTION_STATUS.CANCELED))) ? 
                    <div className='holder-btn-group d-flex flex-wrap mg-t-20'>
                        <div className={classNames('col-12 pd-lr-10 mg-b-10 col-md-6')}>
                            <button className={classNames('holder-btn d-flex justify-content-center align-items-center holder-btn-remove col-12', isLock && 'disabled-remove-card')}
                                onClick={() => !isLock && openConfirmRemoveCard(id)}>
                                <img src='/images/icon_x_fill.svg' />
                                <span className='text-color-cs-2 font-bold mg-l-10 fs-label'>カード放棄</span>
    
                            </button>
                        </div>
                        <div className={classNames('col-12 pd-lr-10 mg-b-10 col-md-6')}>
                            <button className='holder-btn d-flex justify-content-center align-items-center holder-btn-payment col-12'
                                onClick={() => openPurchaseCard({ cardPrice, id })}>
                                <img src='/images/icon_wallet.svg' />
                                <span className='text-color-cs-2 font-bold mg-l-10 fs-label'>決済</span>
                            </button>
                        </div>
                    </div> : 
                    <div className='mg-tb-20 w-100 d-flex justify-content-center'>
                        {
                            transaction && transaction?.status === TRANSACTION_STATUS.UNPAID && 
                            <div className='warning-card-purchase d-flex justify-content-center align-items-center'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <i className='fa-solid fa-triangle-exclamation mg-r-5' style={{color: '#DD2F24', fontSize: '20px'}}></i>
                                    <span className='font-semi-bold' style={{color: '#DD2F24', fontWeight: '700'}}>支払い待ちのカード </span>
                                </div>
                                <span style={{color: '#DD2F24', fontWeight: '700'}}>(取引ID: {transaction?.code || ''})</span>
                            </div>
                        }
                    </div>
                ) : (
                    sortCardStatus?.cardId === id && 
                    <div className='holder-btn-group d-flex justify-content-center mg-t-20 mg-b-10 pd-lr-5'>
                        <button className={classNames('holder-btn d-flex justify-content-center align-items-center holder-btn-remove holder-btn-exit-sort-card col-12 flex-gap-5')}
                            onClick={onExitSortCard}>
                            <i className='fa-regular fa-circle-xmark'></i>
                            <span className='text-color-cs-2 font-bold fs-label'>カードの並べ替えをキャンセル</span>
                        </button>
                    </div>
                )
            )
            : <div className='mg-t-10'></div>
        }
        {
            holderTransaction && holderTransaction?.status === TRANSACTION_STATUS.UNPAID && (
                <div className='warning-card-holder-purchase font-bold d-flex align-items-center justify-content-center'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <i className='fa-solid fa-triangle-exclamation mg-r-5'></i>
                        <span>このHolderには支払い待ちの取引が存在します。</span>
                    </div>
                    <span>{` (取引ID: ${holderTransaction.code})`}</span>
                </div>
            )
        }
    </div>

}

export default Card;