/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { useState } from 'react';
import PurchaseMember from './PurchaseMember';
import '../style.scss';

const HolderAllow = observer((props) => {   
    //state
    const [navigateToPaidMember, setNavigate] = useState(false);
    const [isShow, setShow] = useState(true);
    
    //function
    const onChangeNavigate = (value) => setNavigate(value);

    return isShow ? 
        <>
            { !navigateToPaidMember ? (<div className='modal-default modal-paid-member pd-t-20 pd-lr-16 '>
                <p className='fs-heading-normal text-center font-semi-bold'>有料会員でないため、この機能は使用できません。</p>
                <div className='d-flex justify-content-center mg-t-30 mg-b-15'>
                    <button className='btn btn-bg-cs-1 min-width-150' onClick={() => setNavigate(!navigateToPaidMember)}><span className='font-bold'>有料会員はコチラから</span></button>
                </div>
            </div>)
                : (
                    <PurchaseMember 
                        navigateToPaidMember={navigateToPaidMember} 
                        onChangeNavigate={onChangeNavigate} 
                        callbackSendMailMessage={() => {
                            setShow(isShow);
                            setNavigate(!navigateToPaidMember);
                        }}
                        callbackSubmit={() => {
                            setShow(!isShow);
                        }}
                    />
                )
            }
        </> : <></>
})

export default HolderAllow;