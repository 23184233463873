import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { MSG, SYSTEM_PATH, TYPE_GENERAL_SETTING } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import { furiganaRegex } from '../../core/utils/common';
import { useState } from 'react';

import './styles.scss';
import { EditorCustom } from '../../components';

const RegisterScreen = observer(() => {
    // other
    const navigate = useNavigate();

    // store
    const { authStore: { registerMember }, modalStore: { show, hide }, generalSettingStore: { getGeneralSetting } } = useStore();

    /// state
    const validateRegisterSchema = yup.object().shape({
        userName: yup
            .string()
            .required(MSG['error.required']),
        fullName: yup
            .string()
            .required(MSG['error.required']),
        fullNameFuri: yup
            .string()
            .matches(new RegExp(furiganaRegex), MSG['error.furigana_format'] )
            .nullable(),
        email: yup
            .string()
            .required(MSG['error.required'])
            .email(MSG['error.email_format']),
    })
    const { 
        register, 
        handleSubmit, 
        formState: { errors, isSubmitting } 
    } = useForm({resolver: yupResolver(validateRegisterSchema), mode: 'onChange'});

    const [showRegisterSuccess, setShowRegisterSuccess] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    // function
    const onSubmit = async ( { userName, fullName, fullNameFuri, email } ) => {
        try {
            const res = await registerMember({ userName, fullName, fullNameFuri, email });
            if(res?.ok) {
                setShowRegisterSuccess(!showRegisterSuccess);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const redirectToLogin = () => {
        navigate(SYSTEM_PATH.LOGIN);
    }

    const onClickCheckBoxTerms = async() => {
        const res = await getGeneralSetting(TYPE_GENERAL_SETTING.TERMS);
        const { data: { value } } = res;
        show({
            id: 'modal-alert',
            isOpen: true,
            notButtonX: false,
            header: '利用規約',
            onCancel: () => { hide() },
            children: (
                <div className='bg-cs-white fs-cs-14 border-radius-bottom-5 color-original'>
                    <div className='max-width-960 max-height-560 overflow-auto pd-15'>
                        <EditorCustom data={value} isReadOnly={true}/>
                    </div>
                </div>
            ),
            type: 'medium'
        })
    }

    const onChangeCheckBox = () => {
        setIsChecked(!isChecked);
    }

    return(
        <div className='register-screen wrapper-content-form'>
            {showRegisterSuccess ? (
                <div className='text-center text-color-cs-white'>
                    <i className='fa-solid fa-circle-check fs-cs-80 mg-t-100'></i>
                    <div className='mg-t-18 fs-cs-16 font-semi-bold'>アカウント登録が完了しました!</div>
                    <div className='mg-t-18 fs-cs-13 font-400'>
                        <div>登録されているメールアドレスに認証メールを送信しました。</div>
                        <div>電子メールに記載されているリンクをクリックして、認証を完了してください。</div>
                    </div>
                    <button 
                        className='btn btn-bg-cs-1 w-100 mg-t-100'
                        onClick={redirectToLogin}
                    >ログイン</button>
                </div>
            ) : (
                <>
                    <form onSubmit={handleSubmit(onSubmit)} className='mg-t-35'>
                        <div className='title fs-heading-large text-white font-semi-bold'>
                        アカウント登録
                        </div>
                        <div className='mg-t-15'>
                            <div className='form-floating input-floating'>
                                <input 
                                    {...register('userName')} 
                                    id='userName' 
                                    type={'text'} 
                                    placeholder='userName' 
                                    className='form-control'
                                    maxLength={12}/>
                                <label htmlFor='userName'>
                                    ユーザーネーム
                                    <span className='text-color-cs-red'> * </span> 
                                    <span className='text-color-cs-7'>(12文字以内)</span> 
                                </label>
                            </div>
                            {
                                errors?.userName &&
                                    <div className='text-danger mg-t-5 fs-error'>{errors.userName?.message}</div>
                            }
                        </div>
                        <div className='mg-t-15'>
                            <div className='form-floating input-floating'>
                                <input 
                                    {...register('fullName')} 
                                    id='fullName' 
                                    type={'text'} 
                                    placeholder='fullName' 
                                    className='form-control'
                                    maxLength={24}/>
                                <label htmlFor='fullName'>
                                    名前 - 本名
                                    <span className='text-color-cs-red'> * </span> 
                                    <span className='text-color-cs-7'>(24文字以内)</span> 
                                </label>
                            </div>
                            {
                                errors?.fullName &&
                                    <div className='text-danger mg-t-5 fs-error'>{errors.fullName?.message}</div>
                            }
                        </div>
                        <div className='mg-t-15'>
                            <div className='form-floating input-floating'>
                                <input 
                                    {...register('fullNameFuri')} 
                                    id='fullNameFuri' 
                                    type={'text'} 
                                    placeholder='fullNameFuri' 
                                    className='form-control'
                                    maxLength={24}/>
                                <label htmlFor='fullNameFuri'>
                                    名前 - フリガナ
                                    <span className='text-color-cs-7'> (24文字以内)</span> 
                                </label>
                            </div>
                            {
                                errors?.fullNameFuri &&
                                    <div className='text-danger mg-t-5 fs-error'>{errors.fullNameFuri?.message}</div>
                            }
                        </div>
                        <div className='mg-t-15'>
                            <div className='form-floating input-floating'>
                                <input {...register('email')} id='email' type={'text'} placeholder='email' className='form-control'/>
                                <label htmlFor='email'>
                                    メールアドレス
                                    <span className='text-color-cs-red'> *</span> 
                                </label>
                            </div>
                            {
                                errors?.email &&
                                    <div className='text-danger mg-t-5 fs-error'>{errors.email?.message}</div>
                            }
                        </div>
                        <div className='mg-t-30'>
                            <div className='position-relative d-flex align-items-center text-color-cs-white gap-2 mg-l-4'>
                                <input 
                                    {...register('checkBoxTerms')} 
                                    id='checkBoxTerms' 
                                    type={'checkbox'}
                                    onClick={onChangeCheckBox}/>
                                <label htmlFor='checkBoxTerms' className='font-regular'>
                                    {
                                        isChecked && <i className='checkbox-icon fs-cs-12 fa-solid fa-check'></i>
                                    }
                                    <label 
                                        htmlFor='' 
                                        className='mg-l-25 text-color-cs-4'
                                        onClick={onClickCheckBoxTerms}>利用規約</label>に同意します。
                                </label>
                            </div>
                        </div>
                        <button type='submit' className='btn btn-bg-cs-1 w-100 mg-t-40' disabled={!isChecked || isSubmitting}>アカウント登録</button>
                    </form>
                    <div className='form-bottom'>
                        <div className='text-center'>
                            <span className='fs-label mg-r-5 text-white'>既にアカウントをお持ちですか?</span> 
                            <Link to={SYSTEM_PATH.LOGIN} className='hover-underline fs-label'>ログイン</Link>
                        </div>
                    </div>
                </>
            )}
            
        </div>
    );
});

export default RegisterScreen;