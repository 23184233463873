import { toJS } from 'mobx';
import { useEffect, useMemo, useState } from 'react';
import { TEXT_MONTHLY } from '../../core/configs/constants';
import moment from 'moment';
import { FormatNumber } from '../../components';

const ExtendHolderList = (props) => {
    // props
    const { formMethods: { watch, setValue, reset }, accountId, holderId, data, holderFee} = props;

    // state
    const watchExtendHolder = watch('extendHolder');

    const [dataOriginal, setDataOriginal] = useState(toJS(data));
    const dataSelected = useMemo(() => {
        if(!watchExtendHolder) return [];
        return watchExtendHolder.map(e => Number(e.accountId))
    }, [JSON.stringify(watchExtendHolder)])

    // life cycle
    useEffect(() => {
        if (accountId && holderId) {
            const accountCurrent = data.find(item => item.id === accountId);
            if(accountCurrent) {
                const formatAccountCurrent = formatData([toJS(accountCurrent)], holderId);
                reset({
                    extendHolder: formatAccountCurrent
                })
            }
        }
    }, [accountId, holderId])

    // function
    const formatData = (data, holderId = null) => {
        return data ? data.map(item => {
            return {
                accountId: item?.id,
                holders: item?.holders?.map((holder) => {
                    return {
                        holderId: holder.id,
                        expiryStartDate: holder.expiryStartDate,
                        monthHolder: 1,
                        isChecked: holderId == holder.id ? true : false
                    }
                })
            }
        }) : []
    }

    const handleAddExtendHolder = () => {
        const dataCanAdd = formatData([dataOriginal.find(dataOriginalItem => !dataSelected.includes(dataOriginalItem.id))]);
        if (dataCanAdd) {
            setValue('extendHolder', [...watchExtendHolder || [], {
                accountId: dataCanAdd[0].accountId,
                holders: dataCanAdd[0].holders
            }])
        }
    }

    return (
        <>
            {
                watchExtendHolder?.length > 0 &&
                watchExtendHolder.map((item, index) => (
                    <ExtendHolderItem 
                        key={index} 
                        itemIndex={index}
                        formMethods={props.formMethods}
                        dataOriginal={dataOriginal}
                        dataSelected={dataSelected}
                        watchExtendHolder={watchExtendHolder}
                        holderFee={holderFee}
                        formatData={formatData}
                    />
                ))
            }
            {
                dataOriginal?.length != dataSelected?.length && (
                    <div className='d-flex justify-content-center mg-t-15'>
                        <button className='btn btn-cs-1 text-color-cs-white btn-common' type='button' onClick={handleAddExtendHolder}>
                            <i className='fa-solid fa-circle-plus'></i>
                            <span className='mg-l-5 font-semi-bold'>別のアカウントを追加</span>
                        </button>
                    </div>
                )
            }
        </>
    )
}

const ExtendHolderItem = (props) => {

    // props
    const { formMethods: { register, watch, setValue }, 
        itemIndex, dataOriginal, dataSelected, watchExtendHolder, holderFee, formatData } = props;

    // state
    const [ listHoldersAccounts, setListHoldersAccounts ] = useState(dataOriginal);
    const watchHolders = watch(`extendHolder.${itemIndex}.holders`);
    const watchAccountId = watch(`extendHolder.${itemIndex}.accountId`);

    // function
    const hanldeRemovePurchaseHolder = () => {
        setValue('extendHolder', [
            ...watchExtendHolder.slice(0, itemIndex), 
            ...watchExtendHolder.slice(itemIndex + 1)
        ])
    }

    const handleChangeAccountId = (e) => {
        setValue(`extendHolder.${itemIndex}.holders`, 
            formatData(dataOriginal).find(item => item.accountId == Number(e.target.value)).holders, { shouldValidate: true })
    }

    const disableOptions = useMemo(() => {
        return listHoldersAccounts.filter(e => dataSelected.includes(e.id) && e.id !== Number(watchAccountId)).map(e => e.id)
    }, [JSON.stringify(dataSelected), watchAccountId])

    return (
        <>
            <div className='extend-holder-item row'>
                <div className='col-11 mg-t-20 pd-r-0'>
                    <div className='fs-label mg-b-5'>アカウント</div>
                    <select 
                        {...register(`extendHolder.${itemIndex}.accountId`,
                            {
                                onChange: handleChangeAccountId
                            }
                        )}
                        className='select-custom height-45 w-100'>
                        {
                            listHoldersAccounts.map((item, index) => {
                                return (
                                    <option value={item.id} key={index} disabled={disableOptions.includes(item.id)}>{item.userName}</option>
                                )
                            })
                        }
                    </select>
                    <div className='pd-lr-20'>
                        {
                            watchHolders?.length > 0 && 
                            watchHolders?.map((item, index) => (
                                <ExtendHolderOption 
                                    data={item}
                                    holderNumber={index + 1} 
                                    key={index} 
                                    itemIndex={itemIndex}
                                    formMethods={props.formMethods}
                                    holderFee={holderFee} />
                            ))
                        }
                    </div>
                </div>
                {
                    itemIndex != 0 && (
                        <div className='col-0-5 extend-holder-delete-btn'>
                            <div
                                style={{cursor: 'pointer'}} 
                                onClick={hanldeRemovePurchaseHolder}
                                className='col-0-5 pd-0 text-center mg-t-60'>
                                <i className='fa-regular fa-trash-can fs-description-large'></i>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className='purchase-holder-line mg-t-20'></div>
        </>
    )
}

const ExtendHolderOption = ({ data, holderNumber, itemIndex, formMethods, holderFee }) => {
    // props
    const { register, watch, setValue } = formMethods;

    // state
    const watchMonthHolder = watch(`extendHolder.${itemIndex}.holders.${holderNumber-1}.monthHolder`);
    const watchIsChecked = watch(`extendHolder.${itemIndex}.holders.${holderNumber-1}.isChecked`);

    // function
    const handleCheck = (e) => {
        setValue(`extendHolder.${itemIndex}.holders.${holderNumber-1}.isChecked`, e.target.checked, { shouldValidate: true });
    }

    return (
        <div className='extend-holder-option row align-items-center justify-content-between mg-t-20'>
            <label className='container-checkbox col-2-5 pd-0 mg-l-12'>
                <span className='mg-l-40 fs-heading-normal font-semi-bold'>{`Holder ${holderNumber}:`}</span>
                <input
                    // {...register(`extendHolder.${itemIndex}.holders.${holderNumber-1}.isChecked`)}
                    checked={watchIsChecked}
                    onChange={handleCheck}
                    type='checkbox' />
                <span className='checkmark'></span>
            </label>
            <div className='container-date-expiry col-2-5 pd-0'>
                <div className='fs-label font-regular'>Holderの有効期限</div>
                <div className='fs-label font-medium text-color-cs-red'>
                    {
                        moment(data?.expiryStartDate).format('YYYY/MM/DD HH:mm')
                    }
                </div>
            </div>
            <div className='col-4 pd-0 fs-label d-flex align-items-center'>
                {
                    watchIsChecked && (
                        <>
                            <span className='width-220'>新しい利用期間</span>
                            <select 
                                {...register(`extendHolder.${itemIndex}.holders.${holderNumber-1}.monthHolder`)}
                                className='select-custom height-45 w-100'>
                                {
                                    TEXT_MONTHLY.map((item, index) => (
                                        <option key={index} value={index+1}>{item}</option>
                                    )) 
                                }
                            </select>
                        </>
                    )
                }
            </div>
            <div className='container-price col-2-5 pd-0 text-center'>
                {
                    watchIsChecked && (
                        <div>支払総額 <span className='fs-heading-normal text-color-cs-medium-yellow font-semi-bold'>
                            <FormatNumber
                                className='fs-heading-normal text-color-cs-medium-yellow font-semi-bold' 
                                statusDisplay='text' 
                                value={watchMonthHolder*holderFee} 
                                suffix=' 円' />
                        </span></div>
                    )
                }
            </div>
        </div>
    )
}

export default ExtendHolderList;